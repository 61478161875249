import PropTypes from "prop-types";
import { useState } from "react";
import { Container, Grid, Box, Drawer } from "@mui/material";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import chroma from "chroma-js";
import { Img } from "react-image";

import { colors, breakpoints, FromBottom } from "../theme";
import { useGetCounsilQuery } from "../store";
import { getHelmet } from "../utilities";
import Spinner from "../components/Spinner";

import hexagonMask from "../assets/mask-hexagon.png";
import hexagonBg from "../assets/bg-hexagon.svg";
import logoBG from "../assets/ig.svg";
import declarationDoc from "../assets/docs/interetgeneral-declaration_principes_conseil_orientation_scientifique.pdf";
import presseDoc from "../assets/docs/interetgeneral-dossier_presse_conseil_orientation_scientifique.pdf";

const Content = styled(Container)`
  > h1 {
    color: ${colors.secondary.main};
    font-size: 3.5rem;
    font-weight: 900;
    margin: 0;
    text-align: center;

    @media (min-width: ${breakpoints.sm}px) {
      font-size: 4.5rem;
    }
  }

  > h2 {
    color: ${colors.secondary.light};
    font-size: 2.1rem;
    margin: 32px 16px 0;

    span {
      background: url(${hexagonBg}) no-repeat center;
      background-size: cover;
      color: white;
      display: inline-block;
      height: 34px;
      line-height: 30px;
      margin-right: 8px;
      text-align: center;
      width: 30px;
    }

    @media (min-width: ${breakpoints.sm}px) {
      font-size: 2.3rem;
      margin: 48px 40px 0;

      span {
        height: 47px;
        line-height: 42px;
        margin-right: 16px;
        width: 42px;
      }
    }

    @media (min-width: ${breakpoints.md}px) {
      font-size: 2.8rem;
    }
  }

  > p {
    margin: 16px 16px 0;

    @media (min-width: ${breakpoints.sm}px) {
      margin: 8px 40px 0 96px;
    }

    a {
      color: ${colors.secondary.main};
      text-decoration: none;
      font-weight: 700;

      :hover {
        text-decoration: underline;
      }
    }
  }
`;

const Members = styled(Grid)`
  margin-top: 72px;
  padding: 56px 0 0;
  position: relative;

  @media (min-width: ${breakpoints.sm}px) {
    margin-top: 40px;
    padding: 80px 80px 0;
  }

  :before {
    background: ${colors.secondary.light};
    content: "";
    display: block;
    height: 6px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%);
    width: 35%;
  }
`;

const Member = styled.article`
  margin-bottom: 56px;
  text-align: center;

  @media (min-width: ${breakpoints.sm}px) {
    margin-bottom: 80px;
  }

  h1 {
    color: ${colors.primary.main};
    font-size: 2.4rem;
    font-weight: 900;
    margin: 24px 0 8px;

    + div {
      transition: 0.3s;
    }
  }

  ${(props) =>
    props.selectable &&
    `
  cursor: pointer;

  :hover {
    h1 + div {
      color: ${colors.primary.main};
    }

    > div:first-of-type {
      img {
        transform: scale(1.125) translateZ(0);
      }

      :before {
        opacity: 0.8;
      }
    }
  }
  `}
`;

const Image = styled.div`
  height: 216px;
  margin: auto;
  overflow: hidden;
  padding: 8px 0;
  position: relative;
  width: 200px;

  img {
    height: 100%;
    position: relative;
    transition: 0.8s;
    width: 100%;
    z-index: 0;
  }

  :before,
  :after {
    display: block;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  :before {
    background: linear-gradient(
      130deg,
      ${chroma(colors.secondary.light).alpha(0.85).css()} 40%,
      ${chroma(colors.primary.main).alpha(0.85).css()}
    );
    opacity: 0;
    transition: 0.8s;
    z-index: 1;
  }

  :after {
    background: url(${hexagonMask});
    z-index: 2;
  }
`;

const More = styled(Drawer)`
  article {
    max-width: 600px;
    min-width: 300px;
    padding: 40px;
    width: 50vw;
  }

  h1 + div {
    border-bottom: 6px solid ${colors.secondary.light};
    margin-bottom: 24px;
    padding-bottom: 16px;
  }

  a {
    color: ${colors.secondary.main};
    font-weight: 700;
  }
`;

function Counsil({ match }) {
  const [memberSelected, setMemberSelected] = useState();
  const { data, isLoading, isError } = useGetCounsilQuery();

  if (isLoading) {
    return (
      <Container sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
        <Grid container>
          <Grid item xs>
            <Box sx={{ mt: 16 }}>
              <Spinner />
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (isError || !data.items.length) {
    return (
      <Container sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
        <p>Une erreur est survenue. Essayez de recharger la page.</p>
      </Container>
    );
  }

  return (
    <FromBottom>
      <Helmet>
        {getHelmet(
          "Le conseil d'orientation scientifique",
          `Présentation du conseil d'orientation scientifique d'Intérêt Général.`,
          match.url
        )}
      </Helmet>
      <Content sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
        <h1>Conseil d'orientation scientifique</h1>
        <h2>
          <span>1</span> Déclaration de principes et missions du conseil
        </h2>
        <p>
          La{" "}
          <a href={declarationDoc} target="_blank">
            déclaration de principes
          </a>{" "}
          est un texte publié en même temps que la composition du conseil
          d’orientation scientifique. Elle a vocation à formaliser brièvement ce
          que signifie la participation à ce conseil.
        </p>
        <h2>
          <span>2</span> Les membres du conseil d'orientation scientifique.
        </h2>
        <p>
          Retrouvez toutes les biographies dans ce{" "}
          <a href={presseDoc} target="_blank">
            dossier de presse
          </a>
          .
        </p>

        <Members container>
          {data.items.map((member, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Member onClick={() => setMemberSelected(i)} selectable>
                <Image>
                  <Img
                    src={[
                      process.env.RAZZLE_API_URL + member.photo_list_item_url,
                      logoBG,
                    ]}
                    alt={member.firstname + " " + member.lastname}
                  />
                </Image>
                <h1>
                  {member.firstname} {member.lastname}
                </h1>
                <div>{member.title}</div>
              </Member>
            </Grid>
          ))}
        </Members>

        <More
          anchor="right"
          open={memberSelected !== undefined}
          onClose={() => setMemberSelected()}
        >
          {memberSelected !== undefined && (
            <Member>
              <Img>
                <img
                  src={
                    process.env.RAZZLE_API_URL +
                    data.items[memberSelected].photo_list_item_url
                  }
                  alt={
                    data.items[memberSelected].firstname +
                    " " +
                    data.items[memberSelected].lastname
                  }
                />
              </Img>
              <h1>
                {data.items[memberSelected].firstname}{" "}
                {data.items[memberSelected].lastname}
              </h1>
              <div>{data.items[memberSelected].title}</div>
              {parse(sanitizeHtml(data.items[memberSelected].biography))}
            </Member>
          )}
        </More>
      </Content>
    </FromBottom>
  );
}

Counsil.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Counsil;

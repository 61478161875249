import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Container, Grid } from "@mui/material";

import { useGetNoteQuery } from "../store";
import { getHelmet } from "../utilities";
import { FromBottom } from "../theme";
import PageLoader from "../components/PageLoader";
import Headline from "../components/notes/NoteHeadline";
import Content from "../components/notes/NoteContent";
import Sidebar from "../components/Sidebar";

function Note({ match, history }) {
  const {
    data: note,
    isLoading,
    isError,
  } = useGetNoteQuery({
    slug: match.params.slug,
  });

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError) {
    history.push("/");
  }

  return (
    <>
      <Helmet>
        {getHelmet(
          note.title,
          note.description,
          match.url,
          process.env.RAZZLE_API_URL + note.cover_social_share_url
        )}
      </Helmet>
      <Headline note={note} noteSx={{ pl: { sm: "220px" } }} />
      <FromBottom>
        <Container sx={{ mb: "80px" }}>
          <Grid
            container
            spacing={{ xs: 4, md: 6, lg: 8 }}
            sx={{ pl: { sm: "220px" } }}
          >
            <Grid item xs>
              <Content note={note} />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: { xs: "none", md: "block" },
                mt: "40px",
              }}
            >
              <Sidebar />
            </Grid>
          </Grid>
        </Container>
      </FromBottom>
    </>
  );
}

Note.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Note;

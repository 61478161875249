import { Container, Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";

import { useGetNoteListQuery } from "../store";
import { FromBottom } from "../theme";
import PageLoader from "../components/PageLoader";
import PageError from "../components/PageError";
import Headline from "../components/notes/NoteHeadline";
import NoteItem from "../components/notes/NoteItem";
import Sidebar from "../components/Sidebar";

function Home() {
  const { data, isLoading, isError } = useGetNoteListQuery({
    perPage: 5,
  });

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError || !data.items.length) {
    return <PageError />;
  }

  const notes = [...data.items];
  const headNote = notes.shift();

  return (
    <>
      <Headline note={headNote} withLink />
      <FromBottom>
        <Container sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
          <Grid container spacing={{ xs: 4, md: 6, lg: 8 }}>
            <Grid item xs>
              {notes.map((note, i) => (
                <NoteItem note={note} key={i} />
              ))}
              <Box
                sx={{
                  mt: { md: "80px" },
                  textAlign: { xs: "center", md: "right" },
                }}
              >
                <Link className="button reverse" to="/notes">
                  Voir toutes les notes
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              lg="auto"
              xs={4}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <Sidebar />
            </Grid>
          </Grid>
        </Container>
      </FromBottom>
    </>
  );
}

export default Home;

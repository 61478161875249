import Home from "./pages/Home";
import Note from "./pages/Note";
import Notes from "./pages/Notes";
import Counsil from "./pages/Counsil";
import Tags from "./pages/Tags";
import Search from "./pages/Search";
import Press from "./pages/Press";
import Legal from "./pages/Legal";
import About from "./pages/About";

const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
    apiRequests: [{ endpoint: "getNoteList", params: { perPage: 5 } }],
  },
  {
    path: "/notes/:slug",
    exact: true,
    component: Note,
    apiRequests: [{ endpoint: "getNote" }],
  },
  {
    path: ["/notes", "/notes/page/:page"],
    exact: true,
    component: Notes,
    apiRequests: [{ endpoint: "getNoteList", params: { perPage: 10 } }],
  },
  {
    path: "/conseil",
    exact: true,
    component: Counsil,
    apiRequests: [{ endpoint: "getCounsil" }],
  },
  {
    path: "/presse",
    exact: true,
    component: Press,
  },
  {
    path: "/mentions-legales",
    exact: true,
    component: Legal,
  },
  {
    path: "/qui-sommes-nous",
    exact: true,
    component: About,
  },
  {
    path: ["/tags/:slug", "/tags/:slug/page/:page"],
    exact: true,
    component: Tags,
    apiRequests: [{ endpoint: "getTagList" }],
  },
  {
    path: "/recherche/:query",
    component: Search,
    apiRequests: [{ endpoint: "getSearch" }],
  },
  {
    path: "*",
    component: Home,
    apiRequests: [{ endpoint: "getNoteList", params: { perPage: 5 } }],
  },
];

export default routes;

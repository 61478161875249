import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

import { getHelmet } from "../utilities";
import { colors, breakpoints, FromBottom } from "../theme";

const Content = styled(Container)`
  h1 {
    color: ${colors.secondary.main};
    font-size: 3.5rem;
    font-weight: 900;
    margin: 0;
    text-align: center;

    @media (min-width: ${breakpoints.sm}px) {
      font-size: 4.5rem;
    }
  }

  h2 {
    color: ${colors.secondary.light};
    font-size: 2.1rem;
    margin: 32px 16px 0;
  }

  a {
    color: ${colors.secondary.main};
    text-decoration: none;
    font-weight: 700;

    :hover {
      text-decoration: underline;
    }
  }
`;

function Press({ match }) {
  return (
    <FromBottom>
      <Helmet>
        {getHelmet(
          "Presse",
          `On parle d'Intérêt Général dans la presse.`,
          match.url
        )}
      </Helmet>
      <Content sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
        <h1>Presse</h1>

        <p>
          Pour toute demande presse, merci d’écrire à l’adresse{" "}
          <a href="mailto:presse@interetgeneral.net">
            presse@interetgeneral.net
          </a>{" "}
          ou nous contacter en message privé sur{" "}
          <a href="https://twitter.com/interetgen">@InteretGen</a>.
        </p>

        <h1>On parle de nous</h1>

        <h2>
          Note #29 – Faire sauter les verrous : Les clés pour que la gauche ne
          capitule pas
        </h2>

        <p>
          <i>Révolution permanente</i>, Frédéric Lordon, mars 2024,{" "}
          <a href="https://www.youtube.com/watch?v=g-kd2OL0Uks">
            « Il n'y a pas d'autre solution qu'être révolutionnaire »
          </a>
        </p>

        <p>
          <i>Lundi matin</i>, Renaud Lambert, 21 mars 2023,{" "}
          <a href="https://lundi.am/L-etincelle-contre-revolutionnaire">
            L’étincelle contre-révolutionnaire
          </a>
        </p>

        <h2>Note #28 – Que peut l'état pour renforcer le syndicalisme ? </h2>

        <p>
          <i>Mediapart</i>, Romaric Godin et Dan Israël, entretien avec Sophie
          Béroud, Baptiste Giraud et Karel Yon, octobre 2023,{" "}
          <a href="https://www.mediapart.fr/journal/economie-et-social/121023/il-faut-assumer-la-dimension-politique-du-syndicalisme-pour-construire-une-alternative">
            « Il faut assumer la dimension politique du syndicalisme pour
            construire une alternative »
          </a>
        </p>

        <p>
          <i>La Vie</i>, Hendrik Lindell, entretien avec Maxime Quijoux, mars
          2023,{" "}
          <a href="https://www.lavie.fr/actualite/societe/mouvement-social-les-syndicats-sont-plus-legitimes-que-jamais-87316.php">
            Mouvement social : « Les syndicats sont plus légitimes que jamais »
          </a>
        </p>

        <p>
          <i>Politis</i>, Pierre Jacquier-Zalc, mars 2023,{" "}
          <a href="https://www.politis.fr/articles/2023/03/syndicats-non-grata-retraites-macron/">
            Syndicats non grata
          </a>
        </p>

        <p>
          <i>Mediapart</i>, Edwy Plenel, mars 2023,{" "}
          <a href="https://www.mediapart.fr/journal/politique/080323/retraites-la-grand-mere-des-batailles?utm_source=global&utm_medium=social&utm_campaign=SharingApp&xtor=CS3-5">
            Retraites : la grand-mère des batailles
          </a>
        </p>

        <p>
          <i>Le Monde Diplomatique</i>, Sophie Béroud et Martin Thibault, avril
          2023,{" "}
          <a href="https://www.monde-diplomatique.fr/2023/04/BEROUD/65659">
            Du dialogue social à l’épreuve de force
          </a>
        </p>

        <p>
          <i>Médiapart – la lettre politique</i>, Ilyes Ramdani, 16 janvier
          2023,{" "}
          <a href="https://info.mediapart.fr/optiext/optiextension.dll?ID=qYZqZ0H614wmVv%2BmHDEpMXjVs%2BeST8moJLzJl%2B6G2OwFdxvGzDIz2%2BmldxmpHVgjWLEfqPiejYKoU9339xWHdfi0AeUiU ">
            La vie des idées{" "}
          </a>
        </p>

        <p>
          <i>Public Sénat – Sens public</i>, Maxime Quijoux invité par Thomas
          Hugues,{" "}
          <a href="https://www.publicsenat.fr/emission/sens-public/retraites-la-guerre-d-usure-djihadistes-comment-les-reinserer-230819">
            Retraites : la guerre d'usure
          </a>
        </p>

        <p>
          <i>Le Monde</i>, Tribune de Baptiste Giraud, Maxime Quijoux et Karel
          Yon, 2 mars 2023,{" "}
          <a href="https://www.lemonde.fr/idees/article/2023/03/02/reforme-des-retraites-un-acquis-de-la-mobilisation-exceptionnelle-aura-ete-de-nous-rappeler-la-contribution-essentielle-des-syndicats-a-la-democratie_6163890_3232.html">
            Réforme des retraites : « Un acquis de la mobilisation
            exceptionnelle aura été de nous rappeler la contribution essentielle
            des syndicats à la démocratie »
          </a>{" "}
        </p>

        <p>
          <i>Usbek & Rica</i>, Pablo Maillé, 6 mars 2023,{" "}
          <a href="https://usbeketrica.com/fr/article/retraites-ce-moment-restera-celui-d-une-victoire-syndicale-et-d-une-defaite-democratique ">
            Retraites : « Ce moment restera celui d’une défaite démocratique »
          </a>
        </p>

        <p>
          <i>Liaisons sociales</i>, n°240, mars 2023, entretien de Maxime
          Quijoux et Karel Yon par Judith Chetrit,{" "}
          <a href="https://twitter.com/mquijoux/status/1632738249100673024?s=46&t=uiQAkdde5Owvv4MyIFEU0Q">
            “On parle de dialogue social pour ne plus parler de syndicats”
          </a>
        </p>

        <h2>Note #27 – Socialisation du capital par la loi </h2>

        <p>
          <i>Médiapart</i>, Fabien Escalona, 10 août 2022,{" "}
          <a href="https://www.mediapart.fr/journal/culture-idees/100822/rudolf-meidner-ou-la-douce-euthanasie-du-pouvoir-capitaliste">
            Rudolf Meidner, ou la douce euthanasie du pouvoir capitaliste
          </a>
        </p>

        <p>
          <i>Le Vent Se Lève</i>, entretien avec Hadrien Clouet par Victor
          Woillet, 26 mai 2022,{" "}
          <a href="https://lvsl.fr/hadrien-clouet-la-ligne-de-rupture-est-aujourdhui-majoritaire-dans-le-pays/">
            La ligne de rupture est aujourd’hui majoritaire dans le pays{" "}
          </a>
        </p>

        <h2>
          Note #23 - 100 jours pour une rupture : Quand la gauche essaiera{" "}
        </h2>

        <p>
          <i>Alternatives économiques</i>, Jean-Christophe Catalon, 9 avril
          2022,{" "}
          <a href="https://www.alternatives-economiques.fr/un-think-tank-imagine-cent-premiers-jours-dun-gouvernement-de-gauche/00102935">
            Un think tank imagine les cent premiers jours d’un gouvernement de
            gauche
          </a>
        </p>

        <p>
          <i>Le Vent Se Lève</i>, William Bouchardon, 8 avril 2022,{" "}
          <a href="https://lvsl.fr/fuite-des-capitaux-dette-bras-de-fer-avec-la-finance-comment-resister/">
            {" "}
            Fuite des capitaux, dette, bras de fer avec la finance… comment
            résister ?{" "}
          </a>
        </p>

        <p>
          <i>Médiapart</i>, Romaric Godin, 6 avril 2022,{" "}
          <a href="https://www.mediapart.fr/journal/economie/060422/que-pourrait-faire-la-gauche-au-pouvoir-dans-les-cent-premiers-jours">
            Que pourrait faire la gauche au pouvoir dans les cent premiers jours
            ?
          </a>
        </p>

        <h2>
          Notes #21 et #22 - Planifier l'avenir de notre système électrique{" "}
        </h2>

        <p>
          <i>Le Vent Se Lève</i>, William Bouchardon et Jean-Baptiste Grenier,
          16 juillet 2022,{" "}
          <a href="https://lvsl.fr/risque-de-penurie-energetique-la-france-est-elle-prete/">
            Risque de pénurie énergétique : la France est-elle prête ?
          </a>{" "}
        </p>
        <p>
          <i>Le Vent Se Lève</i>, Anne Debrégeas par William Bouchardon, 9 mars
          2022,{" "}
          <a href="https://lvsl.fr/electricite-cest-le-marche-qui-a-fait-exploser-les-prix-entretien-avec-anne-debregeas/">
            Électricité : « c’est le marché qui a fait exploser les prix »{" "}
          </a>
        </p>

        <h2>
          Note #20 - Pour une République sociale et écologique : Reconstruire le
          triptyque Communes – Départements - État
        </h2>
        <p>
          <i>Mediapart</i>, Fabien Escalona, 9 juillet 2021,{" "}
          <a href="https://www.mediapart.fr/journal/france/090721/elections-locales-comment-redonner-le-gout-du-vote#at_medium=custom7&at_campaign=1047">
            Élections locales : comment redonner le goût du vote
          </a>
        </p>

        <h2>Notes #17 et #18 - Mettre la République en sûreté</h2>
        <p>
          <i>Le Média</i>, 6 juillet 2021,{" "}
          <a href="https://www.lemediatv.fr/emissions/2021/insecurite-oui-une-police-du-peuple-est-possible-GO6-RNV-RD65wUQ7vomKuA">
            Insécurité : oui, une police du peuple est possible
          </a>
        </p>
        <p>
          <i>Au Poste</i>, David Dufresne, 4 juillet 2021,{" "}
          <a
            href="https://www.davduf.net/penser-la-police-au-service-du-peuple
        "
          >
            Penser la Police au service du peuple
          </a>
        </p>
        <p>
          <i>L’insoumission</i>, Boris Bilia & François Thuillier, 2 juillet
          2021,{" "}
          <a href="https://www.linsoumissionhebdo.fr/police-mettre-la-republique-en-surete/">
            Police : Mettre la République en sûreté
          </a>
        </p>
        <p>
          <i>Marianne</i>, Hadrien Mathoux, 19 juin 2021,{" "}
          <a href="https://www.marianne.net/politique/melenchon/quelle-politique-de-securite-pour-la-france-insoumise-un-think-tank-tente-une-reponse">
            Quelle politique de sécurité pour La France insoumise ? Un think
            tank tente une réponse
          </a>
        </p>

        <h2>Note #16 - La garantie d’emploi, solution d’avenir</h2>
        <p>
          <i>Russeurope Express</i>, podcast de Jacques Sapir et Clément
          Ollivier avec Hadrien Clouet et Clément Carbonnier, 28 mai 2021,{" "}
          <a href="https://fr.sputniknews.com/radio_sapir/202105281045660436-chomage-massif-baisser-les-allocations-ou-garantir-l-emploi/">
            Chômage massif: baisser les allocations ou garantir l’emploi ?
          </a>
        </p>
        <p>
          <i>Mediapart</i>, Fabien Escalona, 21 mai 2021,
          <a href="https://www.mediapart.fr/journal/france/210521/garantie-d-emploi-la-proposition-qui-monte-et-fait-debat-gauche">
            Garantie d’emploi : la proposition qui monte et fait débat à gauche
          </a>
        </p>
        <p>
          <i>Le Grand Continent</i>, Clément Carbonnier, 11 avril 2021,
          <a href="https://legrandcontinent.eu/fr/2021/04/11/la-garantie-de-lemploi-larme-sociale-du-green-new-deal/">
            La garantie de l’emploi, l’arme sociale du Green New Deal
          </a>
        </p>

        <h2>
          Notes #13, 14 et 15 - Dette publique, en finir avec les manipulations
        </h2>
        <p>
          <i>AOC</i>, Benjamin Lemoine, 8 juillet 2021,{" "}
          <a href="https://aoc.media/analyse/2021/07/07/quel-public-pour-controler-la-dette/">
            Quel public pour contrôler la dette ?{" "}
          </a>
        </p>
        <p>
          <i>Le podcast du « Diplo »</i>, entretien avec Renaud Lambert par
          Thibault Henneton & Maya B., juin 2021,
          <a href="https://www.monde-diplomatique.fr/podcast/2021-06-BCE-annulation-dettes">
            La BCE doit-elle vraiment annuler les dettes publiques ?
          </a>
        </p>
        <p>
          <i>Le Monde diplomatique</i>, Renaud Lambert, juin 2021,{" "}
          <a href="https://www.monde-diplomatique.fr/2021/06/LAMBERT/63176">
            Quand la dette fissure la gauche française
          </a>
        </p>
        <p>
          <i>Russeurope Express</i>, podcast de Jacques Sapir et Clément
          Ollivier avec Benjamin Lemoine et Raul Sampognaro, 30 avril 2021,{" "}
          <a href="https://fr.sputniknews.com/radio_sapir/202104301045541950-circuit-du-tresor-liberer-la-dette-publique-de-la-finance/">
            Circuit du Trésor : libérer la dette publique de la finance ?{" "}
          </a>
        </p>
        <p>
          <i>Alternatives économiques n°411</i>, Jean-Christophe Catalon, 1er
          avril 2021,{" "}
          <a href="https://www.alternatives-economiques.fr/ressusciter-letat-banquier/00098504">
            Vive la dette ! - Ressusciter l’État-banquier
          </a>
        </p>
        <p>
          <i>Mediapart</i>, Fabien Escalona, 14 février 2021,{" "}
          <a href="https://www.mediapart.fr/journal/france/140121/annulation-de-la-dette-publique-les-gauches-en-quete-de-doctrine?onglet=full">
            Annulation de la dette publique: les gauches en quête de doctrine
          </a>
        </p>

        <h2>Note #12 - Contre le dumping, le protectionnisme</h2>

        <p>
          <i>Le Monde Diplomatique</i>, Morvan Burel, juin 2023,{" "}
          <a href="https://www.monde-diplomatique.fr/2023/06/BUREL/65839">
            Les deux visages de la frontière
          </a>
        </p>

        <p>
          <i>Mémoire des luttes</i>, Morvan Burel, 17 avril 2021,{" "}
          <a href="https://www.medelu.org/Face-au-commerce-international-deregule-batir-un-gouvernement-des-echanges">
            Face au commerce international dérégulé, bâtir un gouvernement des
            échanges protecteur
          </a>
        </p>

        <h2>
          Note #11 - Pour casser les dynasties, inventer l’héritage tout au long
          de la vie
        </h2>
        <p>
          <i>Le Vent Se Lève</i>, Boris Bouzol-Broitman et Ziggy Steiger, 5 mai
          2021,{" "}
          <a href="https://lvsl.fr/pour-favoriser-la-redistribution-taxons-les-grosses-donations/">
            Pour favoriser la redistribution, taxer les grosses donations
          </a>
        </p>

        <p>
          <i>Télérama</i>, Romain Jeanticou, 17 avril 2021,{" "}
          <a href="https://www.telerama.fr/debats-reportages/limpot-sur-les-successions-un-impot-qui-rate-sa-cible-6856790.php">
            L'impôt sur les successions, un impôt qui rate sa cible.
          </a>
        </p>

        <p>
          <i>L’insoumission</i>, Boris Bilia et Boris Bouzol-Broitman, 16 avril
          2021,{" "}
          <a href="https://www.linsoumissionhebdo.fr/heritage-redistribuer-lepargne-et-taxer-les-donations/">
            Héritage : Redistribuer l’épargne et taxer les donations{" "}
          </a>
        </p>

        <p>
          <i>Ouest-France</i>, Le Drench, 7 avril 2021,{" "}
          <a href="https://ledrenche.ouest-france.fr/fiscalite-donations/">
            Faut-il alléger la fiscalité applicable aux successions et aux
            donations ?{" "}
          </a>
        </p>

        <p>
          <i>Marianne</i>, Mathias Thépot, 2 mars 2021,{" "}
          <a href="https://www.marianne.net/economie/economie-francaise/defiscaliser-les-heritages-des-riches-pour-relancer-leconomie-la-derniere-trouvaille-de-bercy">
            Défiscaliser les héritages des riches pour relancer l'économie : la
            dernière trouvaille de Bercy
          </a>
        </p>

        <p>
          <i>Le Monde</i>, 21 février 2021, tribune de Boris Bouzol-Breitman,
          Camille Herlin-Giret, Thomas Piketty, Alexis Spire, Samuel Tracol,{" "}
          <a href="https://www.lemonde.fr/idees/article/2021/02/21/rsa-jeunes-il-est-temps-que-les-plus-fortunes-de-nos-aines-contribuent-a-leur-tour_6070700_3232.html">
            RSA jeunes : « Il est temps que les plus fortunés de nos aînés
            contribuent à leur tour ».
          </a>
        </p>

        <p>
          <i>Le Monde Moderne</i>, entretien d’Alexis Poulin avec Camille Herlin
          Giret, 17 février 2021,{" "}
          <a href="https://www.lemondemoderne.media/pourquoi-faut-il-imposer-lheritage-et-les-donations/">
            Pourquoi faut-il imposer l’héritage et les donations ?
          </a>
        </p>

        <h2>Notes #8 #9 #10 - Des pollutions, dépollution ! </h2>
        <p>
          <i>Reporterre</i>, Tribune d’Intérêt général, 18 décembre 2020,{" "}
          <a href="https://reporterre.net/Face-a-l-etendue-des-pollutions-il-faut-desempoisonner-le-monde">
            Face à l’étendue des pollutions, il faut désempoisonner le monde
          </a>
        </p>

        <h2>Notes #5 #6 #7 - Services publics</h2>

        <p>
          <i>L'Obs</i>, Rémy Dodet, 27 mai 2020,{" "}
          <a href="https://www.nouvelobs.com/politique/20200527.OBS29390/nationaliser-sanofi-des-medecins-fonctionnaires-les-idees-post-covid-d-un-think-tank-proche-de-lfi.html">
            Nationaliser Sanofi, des médecins fonctionnaires… Les idées
            post-Covid d’un think tank proche de LFI
          </a>
        </p>

        <p>
          <i>L'Obs</i>, <i>Intérêt Général lance des idées</i>, édition papier
          du 28 mai 2020.
        </p>

        <p>
          <i>Le Monde</i>, Abel Mestre, 27 mai 2020,{" "}
          <a href="https://www.lemonde.fr/politique/article/2020/05/27/la-gauche-republicaine-veut-se-rearmer-ideologiquement_6040934_823448.html">
            La gauche républicaine veut se réarmer idéologiquement
          </a>
        </p>

        <p>
          <i>Marianne</i>, entretien de Kévin Boucaud-Victoire avec Thomas
          Branthôme, 6 juin 2020,{" "}
          <a href="https://www.marianne.net/economie/thomas-branthome-les-services-publics-sont-par-definition-les-adversaires-du-neoliberalisme">
            Thomas Branthôme : "Les services publics sont par définition les
            adversaires du néolibéralisme"
          </a>
        </p>

        <h2>Note #4 - Intercommunalités </h2>

        <p>
          <i>Reporterre</i>, Tribune de Léa Ferri et Louise Rouan, 20 juillet
          2020,{" "}
          <a href="https://reporterre.net/L-Etat-mise-sur-les-metropoles-au-detriment-de-l-interet-general">
            L’État mise sur les métropoles au détriment de l’intérêt général
          </a>
        </p>

        <p>
          <i>Marianne</i>, Tribune de Léa Ferri et Louise Rouan, 2 juillet 2020,{" "}
          <a href="https://www.marianne.net/agora/humeurs/sans-etat-deconcentre-la-decentralisation-est-un-piege">
            Sans État déconcentré, la décentralisation est un piège
          </a>
        </p>

        <p>
          <i>Marianne</i>, Louis Hausalter et Hadrien Mathoux, 5 mars 2020,{" "}
          <a href="https://www.marianne.net/politique/communautes-de-communes-le-grand-bazar">
            Communautés de communes : le grand bazar
          </a>
        </p>

        <p>
          <i>Politis</i>, Tribune d’Intérêt général, 7 mars 2020,{" "}
          <a href="https://www.politis.fr/articles/2020/03/pour-des-communes-ecologiques-qui-cooperent-librement-41456/">
            Pour des communes écologiques qui coopèrent librement
          </a>
        </p>

        <h2>Note #3 - Retraites</h2>
        <p>
          <i>Politis</i>, Thomas Piketty, 22 janvier 2020,{" "}
          <a href="https://www.politis.fr/articles/2020/01/thomas-piketty-un-neoliberalisme-a-contretemps-41284/">
            « Un néolibéralisme à contretemps »
          </a>
        </p>
        <p>
          <i>L'heure du peuple</i>, 10 janvier 2020,{" "}
          <a href="https://heuredupeuple.fr/retraites-un-autre-projet-est-possible/">
            «&nbsp;Retraites : Un autre projet est possible !&nbsp; »
          </a>
        </p>
        <p>
          <i>Médiapart</i>, Romaric Godin, 22 décembre 2019,{" "}
          <a href="https://www.mediapart.fr/journal/france/221219/retraites-il-n-y-pas-de-contre-reforme-possible-sans-changement-profond-des-politiques-economiques">
            Retraites: il n’y a pas de contre-réforme possible sans changement
            profond des politiques économiques
          </a>
        </p>
        <p>
          <i>Le vent se lève</i>, Antoine Bristielle, 5 décembre 2019,{" "}
          <a href="https://lvsl.fr/retraites-en-marche-vers-la-regression/">
            Retraites : en marche vers la régression
          </a>
        </p>

        <h2>Note #1 - Think tanks</h2>
        <p>
          <i>Là-bas si j'y suis</i>, entretien de Jonathan Duong avec Grégory
          Rzepski, 24 décembre 2019,{" "}
          <a href="https://la-bas.org/la-bas-magazine/entretiens/qui-se-cache-derriere-les-think-tanks">
            Qui se cache derrière les think tanks?
          </a>
        </p>
        <p>
          <i>Le monde diplomatique</i>, Grégory Rzepski, décembre 2019,{" "}
          <a href="https://www.monde-diplomatique.fr/2019/12/RZEPSKI/61104">
            Ces viviers où prolifèrent les «&nbsp;experts&nbsp;» médiatiques
          </a>
        </p>

        <h2>À propos d’Intérêt général</h2>
        <p>
          <i>El Pais</i>,Carla Mascia, juin 2024,{" "}
          <a href="https://elpais.com/internacional/2024-06-27/con-o-sin-melenchon-el-dilema-acuciante-de-la-izquierda-en-francia.html">
            Con o sin Mélenchon, el dilema acuciante de la izquierda en Francia
          </a>
        </p>
        <p>
          <i>L’Humanité</i>, Cyprien Caddeo, 22 octobre 2020,{" "}
          <a href="https://www.humanite.fr/la-galaxie-des-think-tanks-de-gauche-des-labos-didees-et-autant-darmes-pour-la-bataille-695128">
            La galaxie des think tanks de gauche : des labos d’idées et autant
            d’armes pour la bataille
          </a>
        </p>
        <p>
          <i>L'heure du peuple</i>, 10 janvier 2020,{" "}
          <a href="https://heuredupeuple.fr/interet-general-fabrique-de-lalternative/">
            Intérêt Général : Fabrique de l’alternative
          </a>
        </p>
        <p>
          <i>Le vent se lève</i>, Lenny Benbara, 25 septembre 2019,{" "}
          <a href="https://lvsl.fr/comment-la-france-insoumise-est-devenue-un-parti-de-gauche-contestataire/">
            Comment la France insoumise est devenue un parti de gauche
            contestataire
          </a>
        </p>
        <p>
          <i>Les Inrockuptibles</i>, Pauline Thurier, 6 mai 2019,{" "}
          <a href="https://www.lesinrocks.com/2019/05/06/actualite/actualite/interet-general-le-laboratoire-didees-lance-par-des-insoumis/">
            «&nbsp;Intérêt général&nbsp;» : le «&nbsp;laboratoire d’idées&nbsp;»
            lancé par des Insoumis
          </a>
        </p>
        <p>
          <i>Marianne</i>, Manifeste d'Intérêt général, 7 mai 2019,{" "}
          <a href="https://www.marianne.net/debattons/billets/federer-par-les-idees-pour-l-interet-general-lancement-d-un-nouveau-think-tank-de">
            Fédérer par les idées, pour l’intérêt général : lancement d'un
            nouveau think tank de gauche
          </a>
        </p>
        <p>
          <i>Le Monde, </i>, Abel Mestre, 6 mai 2019,{" "}
          <a href="https://www.lemonde.fr/politique/article/2019/05/06/les-insoumis-se-dotent-d-un-laboratoire-d-idees-pour-preparer-la-prise-du-pouvoir_5458619_823448.html">
            Les « insoumis » se dotent d’un «&nbsp;laboratoire d’idées&nbsp;»
            pour bâtir une «&nbsp;alternative&nbsp;» à Macron
          </a>
        </p>

        <h2>À propos du conseil d’orientation scientifique</h2>
        <p>
          <i>Le monde diplomatique</i>, Arnaud Bontemps et Grégory Rzepski,
          novembre 2020,{" "}
          <a href="https://www.monde-diplomatique.fr/2020/11/BONTEMPS/62425">
            Devoir de réserve, un effet d’intimidation
          </a>
        </p>
        <p>
          <i>Politis</i>, Michel Soudais, 10 juillet 2020,{" "}
          <a href="https://www.politis.fr/articles/2020/07/un-an-apres-sa-creation-linteret-general-se-structure-42139/">
            Un an après sa création, l’Intérêt général se structure
          </a>
        </p>

        <p>
          <i>Le Vent Se Lève</i>, Laëtitia Riss, 11 septembre 2020,{" "}
          <a href="https://lvsl.fr/on-ne-fabrique-pas-de-lemancipation-avec-des-arguments-reactionnaires-entretien-avec-stephanie-roza/">
            Entretien avec Stéphanie Roza
          </a>
        </p>

        <p>
          <i>Le Vent Se Lève</i>, Nicolas Vrignaud, 3 septembre 2020,{" "}
          <a href="https://lvsl.fr/cest-le-retour-dune-morale-individualiste-qui-bouscule-le-sens-de-lassurance-chomage-entretien-avec-hadrien-clouet/">
            Entretien avec Hadrien Clouet
          </a>
        </p>
      </Content>
    </FromBottom>
  );
}

Press.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Press;

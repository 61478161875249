import "isomorphic-fetch";
import styled from "@emotion/styled";

import { colors, breakpoints } from "../theme";
import { useState } from "react";
import Spinner from "./Spinner";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const Container = styled.section`
  background: ${colors.secondary.light};
  border-radius: 6px;
  max-width: 350px;
  padding: 12px;
  position: relative;

  h1 {
    color: white;
    font-size: 1rem;
    margin-bottom: 12px;
    text-align: center;
  }

  input {
    border-color: transparent;
    margin-bottom: 8px;
  }

  button {
    width: 100%;
  }

  @media (min-width: ${breakpoints.md}px) {
    padding: 24px;

    h1 {
      font-size: 1.3rem;
    }
  }
`;

const Fetcher = styled.div`
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [modal, setModal] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsFetching(true);
      const res = await fetch("/inscription-newsletter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (res.status === 200) {
        setModal(
          "Vous êtes maintenant inscrit à notre lettre d'informations. Merci !"
        );
      } else {
        const error = await res.text();
        setModal(error);
      }
    } catch (err) {
      setModal("Une erreur est survenue. Veuillez recommencer.");
    }
    setEmail("");
    setIsFetching(false);
  };

  const handleClose = () => setModal("");

  return (
    <Container>
      <h1>Recevoir notre lettre d'informations</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Mon adresse e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="reverse">Je m'inscris</button>
      </form>

      {isFetching && (
        <Fetcher>
          <Spinner />
        </Fetcher>
      )}

      <Dialog open={!!modal.length} onClose={handleClose}>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "2rem" }}>
            {modal}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ fontSize: "2rem" }}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

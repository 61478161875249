import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {
  Container,
  Grid,
  Box,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";

import { useGetTagListQuery } from "../store";
import { getHelmet } from "../utilities";
import { colors, breakpoints, FromBottom } from "../theme";
import Spinner from "../components/Spinner";
import NoteItem from "../components/notes/NoteItem";
import Sidebar from "../components/Sidebar";

const Title = styled.h1`
  color: ${colors.secondary.main};
  font-size: 3.5rem;
  margin: 0 0 56px;

  @media (min-width: ${breakpoints.sm}px) {
    font-size: 4.5rem;
    margin: 0 0 80px;
  }

  div {
    display: inline-block;
    position: relative;

    :after {
      background: ${colors.secondary.main};
      bottom: -12px;
      content: "";
      display: block;
      height: 8px;
      position: absolute;
      width: 25%;
    }
  }
`;

function TagsContent({ page, slug, url }) {
  const { data, isLoading, isError } = useGetTagListQuery({
    page: page || 1,
    slug: slug,
  });

  if (isLoading) {
    return (
      <Grid item xs>
        <Box sx={{ mt: 16 }}>
          <Spinner />
        </Box>
      </Grid>
    );
  }

  if (isError || !data.items.length) {
    return <Redirect to={"/tags/" + slug} />;
  }

  const tagTitle = data.items[0].tags.find((tag) => tag.slug === slug).title;

  return (
    <Grid item xs>
      <Helmet>
        {getHelmet(
          tagTitle,
          `Notes correspondant au thème "${tagTitle}" sur le site d'Intérêt Général.`,
          url
        )}
      </Helmet>
      <Title>
        <div>Thème : {tagTitle}</div>
      </Title>
      {data.items.map((note, i) => (
        <NoteItem note={note} key={i} />
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "88px 0 56px",
        }}
      >
        <Pagination
          color="secondary"
          shape="rounded"
          count={Math.ceil(data.pagination.total / data.pagination.per_page)}
          size="large"
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`/tags/${slug}/${item.page === 1 ? "" : `page/${item.page}`}`}
              {...item}
            />
          )}
        />
      </Box>
    </Grid>
  );
}

TagsContent.propTypes = {
  page: PropTypes.string,
  slug: PropTypes.string.isRequired,
};

function Tags({ match }) {
  return (
    <FromBottom>
      <Container sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
        <Grid container spacing={{ xs: 4, md: 6, lg: 8 }}>
          <TagsContent {...match.params} url={match.url} />
          <Grid
            item
            lg="auto"
            xs={4}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Sidebar />
          </Grid>
        </Grid>
      </Container>
    </FromBottom>
  );
}

Tags.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Tags;

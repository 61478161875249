import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import chroma from "chroma-js";

export const colors = {
  primary: {
    main: "#EE776E",
    light: "#F4A8A3",
    dark: "#6e3842",
  },
  secondary: {
    main: "#3E9EA3",
    light: "#a7d3d7",
    dark: "#409298",
  },
  text: {
    primary: "#3C3C3B",
    secondary: chroma("#3C3C3B").alpha(0.8).css(),
    disabled: chroma("#3C3C3B").alpha(0.5).css(),
  },
  divider: "rgba(0, 0, 0, 0.065)",
};

export const breakpoints = {
  xs: 0,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1536,
};

const fadeIn = keyframes`
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
`;
export const FadeIn = styled.div`
  animation: ${fadeIn} 0.5s linear;
`;

const fromBottom = keyframes`
  from {
    transform: translateY(75px);
  }

  to {
    transform: translateY(0);
  }
`;
export const FromBottom = styled.div`
  animation: ${fromBottom} 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

export const Tags = styled.ul`
  list-style: none;
  margin: 20px 0 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 4px;

    a {
      background: white;
      border-radius: 4px;
      display: inline-block;
      font-size: 1.3rem;
      font-weight: 600;
      box-shadow: 1px 1px 5px ${chroma(colors.secondary.main).alpha(0.2).css()};
      padding: 4px 8px;
      color: ${colors.secondary.main};
      position: relative;
      text-decoration: none;
      transition: 0.3s;
      z-index: 1;

      :hover {
        color: ${colors.primary.main};
      }

      @media (min-width: ${breakpoints.sm}px) {
        font-size: 1.5rem;
      }
    }
  }
`;

import PropTypes from "prop-types";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import chroma from "chroma-js";

import { colors } from "../../theme";
import BackgroundImageLoader from "../BackgroundImageLoader";

const Article = styled(Grid)`
  a {
    color: ${colors.text.primary};
    text-decoration: none;
    width: 100%;

    :hover > div:first-of-type {
      background-size: 110%;

      :before {
        opacity: 0.5;
      }
    }
  }

  h3,
  h2 {
    margin: 0;
    transition: 0.3s;
  }

  h3 {
    color: ${colors.primary.main};
    font-size: 1.5rem;
    margin-bottom: 2px;
  }

  h2 {
    color: ${colors.secondary.main};
    font-size: 1.9rem;
    font-weight: 900;
  }

  blockquote {
    background: ${chroma(colors.text.disabled).alpha(0.03).css()};
    border-radius: 6px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.2rem;
    margin: 16px 0 0;
    padding: 20px 32px 24px;
    word-wrap: break-word;

    em {
      color: ${colors.primary.main};
    }
  }

  p {
    font-size: 1.2rem;
    text-align: right;
    font-style: italic;
  }
`;

const Img = styled.div`
  background: url(${(props) => props.src}) center,
    linear-gradient(
      130deg,
      ${chroma(colors.secondary.light).alpha(0.85).css()} 60%,
      ${chroma(colors.primary.main).alpha(0.85).css()}
    );
  background-size: 100%;
  border-radius: 6px;
  height: 140px;
  margin-bottom: 16px;
  position: relative;
  transition: 0.8s !important;

  :before {
    background-image: linear-gradient(
      130deg,
      ${chroma(colors.secondary.light).alpha(0.85).css()} 60%,
      ${chroma(colors.primary.main).alpha(0.85).css()}
    );
    border-radius: 6px;
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
    content: "";
    display: block;
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: 0.3s;
    width: 100%;
    z-index: 1;
  }
`;

const highlightFields = {
  description: "la description",
  title: "le titre",
  body: "le contenu de l'article",
  tags: "les thèmes",
  extract: "l'extrait",
  "attachment.content": "le document PDF",
};

function SearchItem({ item }) {
  return (
    <Article as="article" container>
      <Link to={"/notes/" + item.slug}>
        <BackgroundImageLoader
          src={process.env.RAZZLE_API_URL + item.cover_list_item_url}
        >
          <Img src={process.env.RAZZLE_API_URL + item.cover_list_item_url} />
        </BackgroundImageLoader>
        {item.topic && <h3>{item.topic}</h3>}
        <h2>{item.title}</h2>
        <blockquote>{parse(sanitizeHtml(item.highlight.fragment))}</blockquote>
        <p>Trouvé dans {highlightFields[item.highlight.field]} de la note.</p>
      </Link>
    </Article>
  );
}

SearchItem.propTypes = {
  item: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    cover_list_item_url: PropTypes.string,
    topic: PropTypes.string,
    title: PropTypes.string.isRequired,
    highlight: PropTypes.shape({
      field: PropTypes.string.isRequired,
      fragment: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SearchItem;

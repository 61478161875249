import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Fade from "@mui/material/Fade";

function BackgroundImageLoader({ src, children }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    var image = document.createElement("img");
    image.src = src;
    image.onload = function () {
      setImageLoaded(true);
    };
    image.onerror = function () {
      setImageLoaded(true);
    };
  }, [src]);

  return (
    <Fade in={imageLoaded !== false} timeout={500}>
      {children}
    </Fade>
  );
}

BackgroundImageLoader.propTypes = {
  src: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BackgroundImageLoader;

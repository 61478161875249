import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import chroma from "chroma-js";

import { colors, breakpoints, Tags } from "../../theme";
import BackgroundImageLoader from "../BackgroundImageLoader";
import hexagonBg from "../../assets/bg-hexagon.svg";

const Cover = styled.div`
  align-items: flex-end;
  color: white;
  display: flex;
  padding: 40px 0 48px;
  position: relative;

  ${(props) =>
    props.animate &&
    `
  :hover .number {
    transform: scale(1.2);
  }

  `}

  > div:first-of-type, .background {
    background-image: linear-gradient(
      130deg,
      ${chroma(colors.secondary.light).alpha(0.85).css()} 60%,
      ${chroma(colors.primary.main).alpha(0.85).css()}
    );
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1 !important;
  }

  .background {
    background-image: linear-gradient(
        130deg,
        ${chroma(colors.secondary.light).alpha(0.85).css()} 60%,
        ${chroma(colors.primary.main).alpha(0.85).css()}
      ),
      url(${(props) => props.url});
    transition: 0.8s;
  }

  > a {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  @media (min-width: ${breakpoints.sm}px) {
    height: 400px;
    padding: 40px 0;

    ${(props) =>
      props.animate &&
      `
    :hover .background {
      background-size: auto 110%;
    }
    `}

    > div:first-of-type, .background {
      background-size: auto 100%;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    ${(props) =>
      props.animate &&
      `
    :hover .background {
      background-size: 110%;
    }
    `}

    > div:first-of-type, .background {
      background-size: 100%;
    }
  }
`;

const Note = styled(Grid)`
  position: relative;

  > div:first-of-type {
    display: flex;
    margin-right: 24px;

    div {
      background: url(${hexagonBg}) no-repeat center;
      background-size: cover;
      font-family: "Alegreya Sans", Arial, Helvetica, sans-serif;
      font-size: 2.4rem;
      font-weight: 700;
      height: 62px;
      line-height: 57px;
      text-align: center;
      transition: 0.8s;
      width: 55px;
    }

    @media (min-width: ${breakpoints.sm}px) {
      align-items: center;
      margin-bottom: 8px;

      div {
        font-size: 2.8rem;
        height: 70px;
        line-height: 65px;
        width: 63px;
      }
    }
  }

  h3,
  h2 {
    margin: 0;
  }

  h3 {
    font-size: 1.7rem;
    margin-bottom: 12px;
    padding-top: 6px;

    @media (min-width: ${breakpoints.sm}px) {
      font-size: 2rem;
      margin-bottom: 4px;
      padding: 0;
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 900;
    margin-bottom: 16px;

    @media (min-width: ${breakpoints.sm}px) {
      font-size: 3.5rem;
      margin-bottom: 12px;
    }

    @media (min-width: ${breakpoints.lg}px) {
      font-size: 4.5rem;
    }
  }

  li a {
    box-shadow: 1px 1px 5px ${chroma(colors.primary.main).alpha(0.2).css()};
    color: ${colors.primary.main} !important;

    :hover {
      color: ${colors.secondary.main} !important;
    }
  }
`;

function NoteHeadline({ note, noteSx, withLink }) {
  return (
    <Cover
      url={process.env.RAZZLE_API_URL + note.cover_header_url}
      animate={withLink}
    >
      <div />
      {note.cover_header_url && (
        <BackgroundImageLoader
          src={process.env.RAZZLE_API_URL + note.cover_header_url}
        >
          <div>
            <div className="background" />
          </div>
        </BackgroundImageLoader>
      )}
      {withLink && <Link to={`/notes/${note.slug}`} aria-label={note.title} />}
      <Container>
        <Note container wrap="nowrap" sx={noteSx}>
          <Grid item>
            <div className="number">#{note.number}</div>
          </Grid>
          <Grid item>
            {note.topic && <h3>{note.topic.title}</h3>}
            <h2>{note.title}</h2>
            {note.tags && (
              <Tags>
                {note.tags.map((tag, i) => (
                  <li key={i}>
                    <Link to={`/tags/${tag.slug}`}>{tag.title}</Link>
                  </li>
                ))}
              </Tags>
            )}
          </Grid>
        </Note>
      </Container>
    </Cover>
  );
}

NoteHeadline.propTypes = {
  note: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    cover_header_url: PropTypes.string,
    number: PropTypes.number.isRequired,
    topic: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired
    ),
  }).isRequired,
  noteSx: PropTypes.object,
  withLink: PropTypes.bool,
};

export default NoteHeadline;

import PropTypes from "prop-types";
import { Container as MUIContainer, Grid, Box } from "@mui/material";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";

import { useGetSearchQuery } from "../store";
import { getHelmet } from "../utilities";
import { colors, breakpoints, FromBottom } from "../theme";
import Spinner from "../components/Spinner";
import SearchForm from "../components/search/SearchForm";
import SearchItem from "../components/search/SearchItem";

const Container = styled(FromBottom)`
  h1 {
    color: ${colors.secondary.main};
    font-weight: 900;
    margin: 0;
    text-align: center;

    strong {
      color: ${colors.primary.main};
    }
  }

  form {
    margin: 16px auto 40px;

    input {
      color: ${colors.primary.main};
      font-weight: 700;
      text-align: center;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    h1 {
      font-size: 4.5rem;
    }

    form {
      margin-bottom: 48px;
      max-width: 50%;
    }
  }
`;

function Search({ match }) {
  const { data, isLoading, isError } = useGetSearchQuery({
    query: match.params.query,
  });

  if (isLoading) {
    return (
      <Grid item xs>
        <Box sx={{ mt: 16 }}>
          <Spinner />
        </Box>
      </Grid>
    );
  }

  if (isError) {
    return (
      <MUIContainer sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
        <p>Une erreur est survenue. Essayez de recharger la page.</p>
      </MUIContainer>
    );
  }

  if (!data.length) {
    return (
      <Container>
        <MUIContainer sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
          <h1>
            Résultats pour la recherche : <strong>{match.params.query}</strong>
          </h1>
          <SearchForm defaultValue={match.params.query} />
          <p style={{ textAlign: "center" }}>
            Aucun élément trouvé pour cette recherche.
          </p>
        </MUIContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Helmet>
        {getHelmet(
          match.params.query,
          `Résultats pour la recherche "${match.params.query}" sur le site d'Intérêt Général.`,
          match.url
        )}
      </Helmet>
      <MUIContainer sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
        <h1>
          Résultats pour la recherche : <strong>{match.params.query}</strong>
        </h1>
        <SearchForm defaultValue={match.params.query} />
        <Grid container spacing={6}>
          {data.map((item, i) => (
            <Grid item md={4} sm={6} key={i}>
              <SearchItem item={item} />
            </Grid>
          ))}
        </Grid>
      </MUIContainer>
    </Container>
  );
}

Search.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      query: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Search;

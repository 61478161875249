import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

import { getHelmet } from "../utilities";
import { colors, breakpoints, FromBottom } from "../theme";

import manifesteDoc from "../assets/docs/interetgeneral-manifeste.pdf";
import declarationDoc from "../assets/docs/interetgeneral-declaration_principes_conseil_orientation_scientifique.pdf";
import presseDoc from "../assets/docs/interetgeneral-dossier_presse_conseil_orientation_scientifique.pdf";

const Content = styled(Container)`
  h1 {
    color: ${colors.secondary.main};
    font-size: 3.5rem;
    font-weight: 900;
    margin: 0;
    text-align: center;

    @media (min-width: ${breakpoints.sm}px) {
      font-size: 4.5rem;
    }
  }

  h2 {
    color: ${colors.secondary.light};
    font-size: 2.1rem;
    margin: 32px 16px 0;
  }

  p,
  li {
    line-height: 2.8rem;
  }

  a {
    color: ${colors.secondary.main};
    text-decoration: none;
    font-weight: 700;

    :hover {
      text-decoration: underline;
    }
  }
`;

function About({ match }) {
  return (
    <FromBottom>
      <Helmet>
        {getHelmet(
          "Qui sommes-nous ?",
          `Un laboratoire d’idées indépendant de recherche politique collégiale et interdisciplinaire.`,
          match.url
        )}
      </Helmet>
      <Content sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
        <h1>Qui sommes-nous ?</h1>

        <h2>
          Un laboratoire d’idées indépendant de recherche politique collégiale
          et interdisciplinaire
        </h2>
        <p>
          <strong>Intérêt général</strong>, est un laboratoire d’idées
          indépendant, composé de représentants des secteurs public, privé et
          universitaire, désireux de démontrer qu’une alternative est possible.
          Il a été initié le 7 mai 2019 par un{" "}
          <a href={manifesteDoc} target="_blank">
            manifeste
          </a>{" "}
          appelant à fédérer par les idées afin de fabriquer l'alternative.
        </p>
        <p>
          En réponse à l’imposture libérale et va-t’en-guerre, ils s’engagent en
          faveur d’une action publique véritablement progressiste et innovante,
          au seul service de l’intérêt général. Face aux urgences écologique,
          démocratique, sociale et internationale, ils proposent des
          orientations et des scénarios d’action concrets à la hauteur des
          enjeux de notre temps. Les travaux d’Intérêt général s’inscrivent dans
          la continuité du travail programmatique de l’Avenir en commun et du
          Nouveau Front populaire. Ils visent à préparer l’action au pouvoir des
          organisations se retrouvant dans ces axes programmatiques.
        </p>
        <p>
          Intérêt général fonde ses travaux sur une{" "}
          <strong>approche interdisciplinaire</strong> alliant des champs et des
          méthodes variés. La contribution croisée d’universitaires, de hauts
          fonctionnaires et de militants permet de produire des analyses et des
          propositions politiques nouvelles, sérieuses et précieuses pour le
          débat public. La méthode de travail d'Intérêt général veille à
          organiser les contributions de façon collégiale. À ce titre, les notes
          publiées sont collectives et non signées individuellement. Nous avons
          à cœur de fonder nos travaux sur les recherches universitaires et les
          pratiques politiques les plus actuelles, en particulier dans le
          respect de la parole de chacune et chacun. Notre laboratoire d’idées
          se veut une interface entre ces milieux et se fixe comme objectif
          premier de mener de{" "}
          <strong>
            <em>la recherche politique</em>
          </strong>
          .
        </p>
        <p>
          Intérêt général compte trois catégories de membres, travaillant main
          dans la main :
        </p>
        <ul>
          <li>
            Fonctionnaires et haut·e·s fonctionnaires des trois fonctions
            publiques et responsables des collectivités territoriales ;
          </li>
          <li>Universitaires et chercheur·se·s</li>
          <li>
            Syndicalistes, dirigeant·e·s de PME, d'associations, cadres du
            secteur privé, professions libérales.
          </li>
        </ul>
        <p>
          Les membres d’Intérêt général s’engagent à titre bénévole – gage
          d’indépendance – à hauteur de leur disponibilité et selon leurs
          centres d’intérêt. Dans un souci de confidentialité des membres,
          engagés individuellement, la liste des membres d’Intérêt général n’est
          pas publique. Collectif bénévole, Intérêt général ne bénéficie d’aucun
          financement institutionnel public ou privé et est uniquement financé
          par des dons de ses membres ou sympathisants.
        </p>
        <h2>Activités et publications</h2>
        <p>
          <strong>Intérêt général</strong> ambitionne de publier une dizaine de{" "}
          <a href="/notes" target="_blank">
            notes thématiques
          </a>{" "}
          par an, dont la coordination est confiée à deux rapporteur·rice·s
          volontaires, soutenu·e·s par l’équipe d’animation. Tous les membres
          peuvent participer à l’élaboration des notes de leur choix et des
          intervenant·e·s extérieur·e·s peuvent être consulté·e·s durant le
          processus d’élaboration. Les notes sont débattues collégialement et
          adoptées au consensus. Les projets de notes sont débattus lors des
          réunions plénières des membres de l’association ou des réunions du{" "}
          <a href="/conseil" target="_blank">
            conseil d’orientation scientifique
          </a>
          . Les notes sont ensuite élaborées dans les groupes de travail, puis
          discutées et enfin adoptées au consensus lors des séances plénières,
          sous la direction éditoriale de l’équipe d’animation.
        </p>
        <p>
          Rendue possible par l’action de ses membres bénévoles, l’action
          d’Intérêt général s’articule ainsi autour de :
        </p>
        <ul>
          <li>
            La publication régulière de notes thématiques. Retrouvez sur cette
            page l’ensemble des notes publiées.
          </li>
          <li>
            L’organisation d’actions de promotion et de débats autour de ses
            propositions, notamment au profit des représentant·e·s politiques
            des organisations du Nouveau Front populaire. Retrouvez sur cette
            page une sélection des reprises presse de nos travaux.
          </li>
          <li>
            Le développement de liens avec d’autres laboratoires d’idées,
            associations et syndicats.
          </li>
        </ul>
        <h2>Origine</h2>
        <p>
          Intérêt général prend racine dans le collectif « article 15 », créé
          pendant la campagne présidentielle de 2017 par Bernard Pignerol, Marie
          Agam et Boris Bilia, en vue de développer les échanges entre hauts
          fonctionnaires proches des idées de la France insoumise, autour des
          propositions du programme L'avenir en commun et de ses livrets.
        </p>
        <p>
          Avec ce socle programmatique pour point de départ, il s’ouvre aux
          mondes de la recherche et de l’entreprise et choisit de faire
          irruption dans le débat public de l’expertise et des idées, dans un
          paysage français et européen dominé par des « think tanks » sous
          emprise libérale (voir{" "}
          <a href="/publications/1.html" target="_blank">
            note #1 d’Intérêt général
          </a>
          ).
        </p>
        <p>
          Les publications d'<strong>Intérêt général</strong> ne reflètent les
          positions officielles d’aucune organisation politique. En proposant
          des orientations libres et constructives, il s’adresse à tous les
          mouvements et à toutes et tous les citoyen·ne·s souhaitant participer
          à la fabrique d’une alternative écologique, sociale, démocratique,
          internationale et humaniste.
        </p>
        <p>
          En 2020, un an après sa création, Intérêt général dévoile la
          composition de son conseil d’orientation scientifique (voir{" "}
          <a href={presseDoc} target="_blank">
            dossier de presse
          </a>{" "}
          et{" "}
          <a href={declarationDoc} target="_blank">
            déclaration de principes
          </a>
          ).
        </p>
      </Content>
    </FromBottom>
  );
}

About.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default About;

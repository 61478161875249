import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import chroma from "chroma-js";

import { colors, breakpoints, Tags } from "../../theme";
import BackgroundImageLoader from "../BackgroundImageLoader";
import hexagonBg from "../../assets/bg-hexagon.svg";

const Article = styled(Grid)`
  margin-bottom: 56px;

  a {
    text-decoration: none;

    :hover {
      h3 {
        color: ${chroma(colors.primary.main).luminance(0.45).css()};
      }

      h2 {
        color: ${chroma(colors.secondary.main).luminance(0.45).css()};
      }
    }
  }

  h3,
  h2 {
    margin: 0;
    transition: 0.3s;
  }

  h3 {
    color: ${colors.primary.main};
    font-size: 1.5rem;
    margin-bottom: 4px;
  }

  h2 {
    color: ${colors.secondary.main};
    font-size: 1.9rem;
    font-weight: 900;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  @media (min-width: ${breakpoints.md}px) {
    h3 {
      font-size: 1.7rem;
    }

    h2 {
      font-size: 2.2rem;
    }

    p {
      text-align: justify;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    h3 {
      font-size: 1.9rem;
    }

    h2 {
      font-size: 2.4rem;
    }
  }
`;

const Img = styled.div`
  background: url(${(props) => props.src}) center,
    linear-gradient(
      130deg,
      ${chroma(colors.secondary.light).alpha(0.85).css()} 60%,
      ${chroma(colors.primary.main).alpha(0.85).css()}
    );
  background-size: cover;
  border-radius: 6px;
  height: 140px;
  position: relative;

  :before {
    background-image: linear-gradient(
      130deg,
      ${chroma(colors.secondary.light).alpha(0.85).css()} 60%,
      ${chroma(colors.primary.main).alpha(0.85).css()}
    );
    border-radius: 6px;
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
    content: "";
    display: block;
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: 0.3s;
    width: 100%;
    z-index: 1;
  }

  div {
    background: url(${hexagonBg}) no-repeat center;
    background-size: cover;
    color: white;
    font-family: "Alegreya Sans", Arial, Helvetica, sans-serif;
    font-size: 1.7rem;
    font-weight: 700;
    height: 47px;
    left: 50%;
    line-height: 42px;
    position: absolute;
    text-align: center;
    bottom: 0;
    transform: translate(-50%, 25%);
    width: 42px;
    z-index: 2;
    transition: 0.3s;
  }

  :hover {
    :before {
      opacity: 0.5;
    }

    div {
      bottom: auto;
      left: 50%;
      right: auto;
      top: 50%;
      transform: translate(-50%, -50%) scale(1.3);
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    height: 176px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    div {
      font-size: 2.2rem;
      height: 56px;
      line-height: 54px;
      width: 50px;
    }
  }
`;

function NoteItem({ note }) {
  return (
    <Article as="article" container spacing={{ xs: 3, lg: 4 }}>
      <Grid item md={4} xs={12}>
        <Link to={`/notes/${note.slug}`}>
          <BackgroundImageLoader
            src={process.env.RAZZLE_API_URL + note.cover_list_item_url}
          >
            <Img src={process.env.RAZZLE_API_URL + note.cover_list_item_url}>
              <div>#{note.number}</div>
            </Img>
          </BackgroundImageLoader>
        </Link>
      </Grid>
      <Grid item xs>
        <Link to={`/notes/${note.slug}`}>
          {note.topic && <h3>{note.topic.title}</h3>}
          <h2>{note.title}</h2>
        </Link>
        <p>{note.extract}</p>
        {note.tags && (
          <Tags>
            {note.tags.map((tag, i) => (
              <li key={i}>
                <Link to={`/tags/${tag.slug}`}>{tag.title}</Link>
              </li>
            ))}
          </Tags>
        )}
      </Grid>
    </Article>
  );
}

NoteItem.propTypes = {
  note: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    cover_list_item_url: PropTypes.string,
    topic: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
    number: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    extract: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default NoteItem;

import styled from "@emotion/styled";

import { breakpoints, FadeIn } from "../theme";

const Container = styled(FadeIn)`
  align-items: center;
  display: flex;
  height: calc(100vh - 56px);
  justify-content: center;

  @media (min-width: ${breakpoints.sm}px) {
    height: calc(100vh - 75px);
  }
`;

function PageLoader() {
  return (
    <Container>
      <p>Une erreur est survenue. Essayez de recharger la page.</p>
    </Container>
  );
}

export default PageLoader;

import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import { colors } from "../theme";

const bounce = keyframes`
  0%, 100% {
    transform: scale(0.2); 
  }

  50% {
    transform: scale(1); 
  }
`;

const Container = styled.div`
  height: calc(${(props) => props.baseValue} * 4);
  margin: auto;
  position: relative;
  width: calc(${(props) => props.baseValue} * 2 * 1.732);

  > div {
    animation: ${bounce} 2s infinite ease-in-out;
    background: ${colors.primary.main};
    border-radius: 5px/10%;
    height: calc(${(props) => props.baseValue} * 2);
    opacity: 0.5;
    position: absolute;
    top: ${(props) => props.baseValue};
    width: calc(${(props) => props.baseValue} * 2 * 1.732);

    &:last-child {
      animation-delay: -1s;
    }

    :before,
    :after {
      background: inherit;
      border-radius: inherit;
      content: "";
      height: inherit;
      position: absolute;
      width: inherit;
    }

    :before {
      transform: rotate(60deg);
    }

    :after {
      transform: rotate(-60deg);
    }
  }
`;

function Spinner({ size }) {
  return (
    <Container baseValue={size + "px"}>
      <div></div>
      <div></div>
    </Container>
  );
}

Spinner.propTypes = {
  size: PropTypes.number,
};

Spinner.defaultProps = {
  size: 30,
};

export default Spinner;

import "isomorphic-fetch";
import { configureStore as createStore } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.browser
      ? process.env.RAZZLE_API_URL + "/1/public"
      : process.env.RAZZLE_API_URL_SSR + "/1/public",
    fetchFn: fetch,
  }),
  endpoints: (builder) => ({
    getNoteList: builder.query({
      query: ({ page = 1 }) =>
        `/notes/?page=${page}&per_page=10&sort=-published_at`,
    }),
    getNote: builder.query({
      query: ({ slug }) => `/notes/slug/${slug}`,
    }),
    getCounsil: builder.query({
      query: () =>
        `/members/?per_page=100&sort=lastname&filters=${JSON.stringify({
          role: "Conseil d'orientation scientifique",
        })}`,
    }),
    getTagList: builder.query({
      query: ({ page = 1, slug }) =>
        `/notes/?page=${page}&per_page=10&sort=-published_at&filters=${JSON.stringify(
          { tags: [slug] }
        )}`,
    }),
    getSearch: builder.query({
      query: ({ query }) => `/search/?q=${query}`,
    }),
  }),
});

export const {
  useGetNoteListQuery,
  useGetNoteQuery,
  useGetCounsilQuery,
  useGetTagListQuery,
  useGetSearchQuery,
} = api;

export default function configureStore(preloadedState) {
  return createStore({
    reducer: { [api.reducerPath]: api.reducer },
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
  });
}

import { useState } from "react";
import styled from "@emotion/styled";
// import { Timeline } from "react-twitter-widgets";
// import { Fade } from "@mui/material";
import { Drawer } from "@mui/material";

import { colors, breakpoints } from "../theme";

import Newsletter from "./Newsletter";
import Spinner from "./Spinner";
import SearchForm from "./search/SearchForm";

const Aside = styled.aside`
  section {
    margin-bottom: 32px;
  }

  h1 {
    font-size: 1.5rem;
    color: ${colors.secondary.main};
    margin: 0 0 24px;

    div {
      display: inline-block;
      position: relative;

      :after {
        background: ${colors.secondary.main};
        bottom: -8px;
        content: "";
        display: block;
        height: 4px;
        position: absolute;
        width: 50%;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    section {
      margin-bottom: 40px;
    }
  }
`;

const Contribution = styled.section`
  button {
    width: 100%;
  }
`;

const ContributionDrawer = styled(Drawer)`
  .spinner {
    padding-top: 80px;
  }

  iframe {
    border: none;
    height: 100%;
    max-width: 100%;
    width: 400px;
    padding-left: 30px;
  }
`;

function Sidebar() {
  const [contributionOpened, setContributionOpened] = useState(false);
  const [contributionLoaded, setContributionLoaded] = useState(false);
  // const [twitterLoaded, setTwitterLoaded] = useState(false);

  // function onTwitterLoaded() {
  //   setTwitterLoaded(true);
  // }

  return (
    <Aside>
      <section>
        <h1>
          <div>Rechercher dans les notes</div>
        </h1>
        <SearchForm />
      </section>

      <Newsletter />

      <Contribution>
        <button onClick={() => setContributionOpened(true)}>
          Contribuer à l'association
        </button>
        <ContributionDrawer
          anchor="right"
          open={contributionOpened}
          onClose={() => {
            setContributionOpened(false);
            setContributionLoaded(false);
          }}
        >
          {!contributionLoaded && (
            <div className="spinner">
              <Spinner />
            </div>
          )}

          <iframe
            src="https://www.helloasso.com/associations/interet-general/formulaires/1/widget"
            onLoad={() => setContributionLoaded(true)}
            style={{ opacity: contributionLoaded ? 1 : 0 }}
          />
        </ContributionDrawer>
      </Contribution>
      {/* <Fade in={twitterLoaded}>
        <section>
          <h1>
            <div>Dernier tweet</div>
          </h1>

          <div>
            <Timeline
              onLoad={onTwitterLoaded}
              dataSource={{
                sourceType: "profile",
                screenName: "InteretGen",
              }}
              options={{
                lang: "fr",
                dnt: true,
                chrome: "noheader, nofooter",
                tweetLimit: 1,
              }}
            />
          </div>
        </section>
      </Fade> */}
    </Aside>
  );
}

export default Sidebar;

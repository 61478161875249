import { useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";

import { colors } from "../../theme";

const Container = styled.form`
  position: relative;

  input {
    :hover,
    :focus,
    :focus-visible {
      + button {
        color: ${colors.secondary.main};
      }
    }
  }

  button {
    background: transparent;
    color: ${colors.text.primary};
    font-size: 2rem;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;

    :hover {
      color: ${colors.primary.main};
    }
  }
`;

function SearchForm({ defaultValue }) {
  const inputEl = useRef(null);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputEl.current.value.length) {
      history.push(`/recherche/${inputEl.current.value}`);
    }
  };

  return (
    <Container onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Rechercher..."
        defaultValue={defaultValue}
        ref={inputEl}
      />
      <button type="submit">
        <i className="eva eva-search" />
      </button>
    </Container>
  );
}

SearchForm.propTypes = {
  defaultValue: PropTypes.string,
};

export default SearchForm;

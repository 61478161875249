import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import styled from "@emotion/styled";
import Fade from "@mui/material/Fade";

import Spinner from "./Spinner";

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 266px;
  text-align: left;
  width: 180px;
`;

function PDFLoader() {
  return (
    <Container>
      <Spinner size={20} />
    </Container>
  );
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PDFPreview({ file }) {
  const [ssrMode, setSsrMode] = useState(true);
  const [pdfLoaded, setPDFLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setSsrMode(false);
    }
  }, []);

  if (ssrMode) {
    return <PDFLoader />;
  }

  function onDocumentLoadSuccess() {
    setPDFLoaded(true);
  }

  return (
    <>
      {!pdfLoaded && <PDFLoader />}
      <Fade in={pdfLoaded} timeout={1200}>
        <div>
          <Document onLoadSuccess={onDocumentLoadSuccess} file={file}>
            <Page pageNumber={1} width={180} height={266} />
          </Document>
        </div>
      </Fade>
    </>
  );
}

PDFPreview.propTypes = {
  file: PropTypes.string.isRequired,
};

export default PDFPreview;

import styled from "@emotion/styled";

import { breakpoints, FadeIn } from "../theme";
import Spinner from "./Spinner";

const Container = styled(FadeIn)`
  align-items: center;
  display: flex;
  height: calc(100vh - 56px);

  @media (min-width: ${breakpoints.sm}px) {
    height: calc(100vh - 75px);
  }
`;

function PageLoader() {
  return (
    <Container>
      <Spinner size={60} />
    </Container>
  );
}

export default PageLoader;

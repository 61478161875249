import styled from "@emotion/styled";
import {
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  createTheme,
  Container,
} from "@mui/material";
import chroma from "chroma-js";
import { Helmet } from "react-helmet";
import { Switch, useLocation, Link } from "react-router-dom";
import { renderRoutes } from "react-router-config";

import "eva-icons/style/eva-icons.css";

import "./assets/fonts/fonts.css";
import { getHelmet } from "./utilities";
import { colors, breakpoints } from "./theme";
import routes from "./routes";
import Menu from "./components/Menu";

const globalStyles = (
  <GlobalStyles
    styles={{
      html: {
        fontSize: "62.5%",
      },
      body: {
        fontFamily: "IBM Plex Serif, Georgia, serif",
        fontSize: "1.6rem",
        lineHeight: 1.4,
        color: colors.text.primary,
      },
      "h1,h2,h3": {
        fontFamily: "Alegreya Sans, Arial, Helvetica, sans-serif",
        textTransform: "uppercase",
      },
      input: {
        borderColor: colors.text.primary,
        borderWidth: "1px !important",
        borderStyle: "solid !important",
        borderRadius: "3px",
        fontFamily: "IBM Plex Serif, Georgia, serif",
        padding: "8px 40px 8px 16px",
        transition: ".3s",
        width: "100%",
        "&:hover, &:focus, &:focus-visible": {
          borderColor: colors.secondary.main,
          outline: 0,
          "&::placeholder": {
            color: colors.secondary.main,
          },
        },
      },
      "::placeholder": {
        color: colors.text.primary,
        transition: ".3s",
      },
      "button, .button": {
        background: colors.primary.main,
        border: 0,
        borderRadius: "4px",
        color: "white",
        cursor: "pointer",
        display: "inline-block",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: "1.4rem",
        fontWeight: 700,
        padding: "12px 16px",
        textAlign: "center",
        textDecoration: "none",
        transition: ".3s",
        "&.reverse": {
          background: colors.secondary.main,
        },
        "&:hover, &:focus, &:focus-visible": {
          background: colors.secondary.main,
          outline: 0,
          "&.reverse": {
            background: colors.primary.main,
          },
        },
      },
      "#root": {
        ".eva": {
          fontSize: "1.6rem",
          lineHeight: 1.8,
        },
      },
    }}
  />
);

const theme = createTheme({
  palette: colors,
  typography: {
    fontFamily: "Alegreya Sans, Arial, Helvetica, sans-serif",
  },
  breakpoints: {
    values: breakpoints,
  },
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: colors.text.secondary,
          fontSize: "1.4rem",
          fontWeight: 700,
        },
        icon: {
          fontSize: "1.5rem",
        },
      },
    },
  },
});

const Content = styled.div`
  min-height: 100vh;
  position: relative;
  padding-bottom: 80px;

  footer {
    background: ${chroma(colors.secondary.light).alpha(0.4).css()};
    bottom: 1px;
    height: 40px;
    left: 0;
    line-height: 40px;
    position: absolute;
    text-align: right;
    width: 100%;

    a {
      color: ${colors.secondary.main};
      font-family: "Alegreya Sans";
      font-weight: 700;
      margin-left: 32px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

function App() {
  const { pathname } = useLocation();
  const description =
    "Intérêt Général est un laboratoire d’idées indépendant, composé de représentants des secteurs public, privé et universitaire, désireux de démontrer qu’une alternative est possible.";

  return (
    <>
      <CssBaseline />
      {globalStyles}
      <Helmet
        defaultTitle="Intérêt Général, la fabrique de l'alternative"
        titleTemplate="%s | Intérêt Général"
      >
        {getHelmet(
          undefined,
          description,
          pathname,
          process.env.RAZZLE_HOSTNAME + ""
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@InteretGen" />
        <meta name="twitter:creator" content="@InteretGen" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Content>
          <Menu />
          <Switch>{renderRoutes(routes)}</Switch>
          <footer>
            <Container>
              <Link to="/mentions-legales">Mentions Légales</Link>
              {/* <Link to="/">Statuts</Link> */}
            </Container>
          </footer>
        </Content>
      </ThemeProvider>
    </>
  );
}

export default App;

import PropTypes from "prop-types";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import styled from "@emotion/styled";

import { dateFormat } from "../../utilities";
import { colors, breakpoints } from "../../theme";
import PDFPreview from "../PDFPreview";

const Preview = styled.div`
  margin: 32px 0 56px;
  text-align: center;

  > div {
    background: white;
    box-shadow: 0 0 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 6px;
    height: 282px;
    margin: 0 auto 24px;
    padding: 8px;
    width: 196px;
  }

  a {
    width: 196px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    margin: 0;
    position: sticky;
    top: calc(282px / 2 + 56px);
    transform: translate(calc(-100% - 32px), -50%);
    width: 196px;
  }
`;

const Date = styled.time`
  color: ${colors.secondary.main};
  display: inline-block;
  font-family: "Alegreya Sans", Arial, Helvetica, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
  margin-left: 24px;
  margin-bottom: 16px;
  padding-top: 12px;
  position: relative;
  text-transform: uppercase;

  :before {
    background: ${colors.secondary.main};
    content: "";
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    height: 6px;
    width: 50%;
  }

  @media (min-width: ${breakpoints.sm}px) {
    margin-bottom: 24px;
  }
`;

const Content = styled.div`
  @media (min-width: ${breakpoints.sm}px) {
    margin-top: -301px;
  }

  a {
    color: ${colors.primary.main};
  }

  li {
    margin-bottom: 16px;
  }
`;

function NoteContent({ note }) {
  return (
    <>
      <Preview>
        <div>
          <PDFPreview file={process.env.RAZZLE_API_URL + note.pdf_url} />
        </div>
        <a
          className="button"
          href={process.env.RAZZLE_API_URL + note.pdf_url}
          target="_blank"
          rel="noreferrer noopener"
        >
          Télécharger
        </a>
      </Preview>
      <Content>
        <Date>{dateFormat(note.published_at)}</Date>
        {parse(sanitizeHtml(note.body))}
      </Content>
    </>
  );
}

NoteContent.propTypes = {
  note: PropTypes.shape({
    published_at: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    pdf_url: PropTypes.string.isRequired,
  }).isRequired,
};

export default NoteContent;

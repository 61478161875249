import dayjs from "dayjs";
import "dayjs/locale/fr";

dayjs.locale("fr");

export function dateFormat(date, format = "MMMM YYYY") {
  return dayjs(date).format(format);
}

export function getHelmet(title, description, pathname, image) {
  return [
    title && <title key="title">{title}</title>,
    description && (
      <meta key="description" name="description" content={description} />
    ),
    <link
      key="canonical"
      rel="canonical"
      href={process.env.RAZZLE_HOSTNAME + pathname}
    />,
    <meta
      key="og:title"
      property="og:title"
      content={
        title
          ? `${title} | Intérêt Général`
          : "Intérêt Général, la fabrique de l'alternative"
      }
    />,
    description && (
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />
    ),
    image && <meta key="og:image" property="og:image" content={image} />,
    <meta
      key="og:url"
      property="og:url"
      content={process.env.RAZZLE_HOSTNAME + pathname}
    />,
  ];
}

import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

import { getHelmet } from "../utilities";
import { colors, breakpoints, FromBottom } from "../theme";

const Content = styled(Container)`
  h1 {
    color: ${colors.secondary.main};
    font-size: 3.5rem;
    font-weight: 900;
    margin: 0;
    text-align: center;

    @media (min-width: ${breakpoints.sm}px) {
      font-size: 4.5rem;
    }
  }

  h2 {
    color: ${colors.secondary.light};
    font-size: 2.1rem;
    margin: 32px 16px 0;
  }

  p, li {
    line-height: 2.8rem;
  }

  a {
    color: ${colors.secondary.main};
    text-decoration: none;
    font-weight: 700;

    :hover {
      text-decoration: underline;
    }
  }
`;

function Legal({ match }) {
  return (
    <FromBottom>
      <Helmet>
        {getHelmet(
          "Mentions légales",
          `Mentions légales du site interetgeneral.net.`,
          match.url
        )}
      </Helmet>
      <Content sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
        <h1>Mentions légales</h1>

        <p>Intérêt Général, la fabrique de l’alternative est <a href="https://www.journal-officiel.gouv.fr/pages/associations-detail-annonce/?q.id=id:202200071555">une association loi 1901</a>.</p>

        <p><strong>Numéro RNA de l’association :</strong> W751263772</p>

        <p><strong>Contact :</strong> <a href="mailto:contact@interetgeneral.net">contact@interetgeneral.net</a></p>

        <p><strong>Siège social :</strong> 57 rue Pernety, 75014 Paris</p>

        <p><strong>Directeur de la publication :</strong> Michel Mongkhoy, président de l’association.</p>

        <p><strong>Hébergeur :</strong> OVH, 2 rue Kellermann, 59100 Roubaix</p>

        <p><strong>Infolettre :</strong> opérée via le service Mailjet.com, 4 rue Jules Lefebvre, 75009 Paris</p>

        <p><strong>Dons :</strong> gestion opérée via le service Helloasso.com, Cité Numérique, 2 Rue Marc Sangnier, 33130 Bègles</p>

        <h2>RÈGLEMENT GÉNÉRAL SUR LA PROTECTION DES DONNÉES (RGPD) DE L’ASSOCIATION INTÉRÊT GÉNÉRAL</h2>

        <h3>Préambule</h3>
        <p>L'association Intérêt Général s'engage à protéger les données personnelles de ses membres, bénévoles, partenaires et autres parties prenantes. Ce règlement définit les principes que l'association suit pour se conformer aux obligations du Règlement (UE) 2016/679 (Règlement Général sur la Protection des Données – GDPR), ainsi que de la loi n°78-17 du 6 janvier 1978 modifiée (loi « informatique et libertés »).</p>

        <h3>1. Responsable du traitement</h3>
        <p>L'association Intérêt Général, dont le siège social est situé 57, rue Pernety, 75014 Paris, est responsable du traitement des données personnelles collectées. Pour toute question relative à la protection des données, vous pouvez contacter notre Délégué à la Protection des Données à l'adresse e-mail suivante : contact@interetgeneral.net</p>

        <h3>2. Collecte et traitement des données</h3>
        <p>L'association collecte les données personnelles suivantes :</p>
        <ul>
          <li>Nom, prénom, date de naissance</li>
          <li>Coordonnées (adresse postale, adresse e-mail, numéro de téléphone)</li>
          <li>Informations liées à l'adhésion (date d'adhésion, type d'adhésion)</li>
          <li>Historique des dons et des participations aux événements</li>
        </ul>
        <p>Les données sont collectées via les formulaires d’inscriptions aux événements, les formulaires de dons, l’inscription à l’infolettre et les communications via notre adresse mail de contact (contact@interetgeneral.net).</p>

        <h3>3. Finalités du traitement</h3>
        <p>Les données personnelles sont collectées pour les finalités suivantes :</p>
        <ul>
          <li>Envoi d'informations sur les activités de l'association</li>
          <li>Gestion des dons et cotisations</li>
          <li>Organisation et suivi des événements</li>
          <li>Respect des obligations légales et réglementaires</li>
        </ul>

        <h3>4. Base légale du traitement</h3>
        <p>Les traitements de données sont fondés sur les bases légales suivantes :</p>
        <ul>
          <li>Respect d'une obligation légale</li>
          <li>Intérêt légitime de l'association à promouvoir ses activités</li>
          <li>Consentement des personnes concernées pour certaines opérations (envoi d’infolettres, utilisation de photos)</li>
        </ul>

        <h3>5. Conservation des données</h3>
        <p>Les données personnelles sont conservées pour la durée nécessaire à la réalisation des finalités décrites ci-dessus, dans le respect des délais légaux de conservation. À l'issue de cette période, les données sont supprimées ou archivées conformément aux normes en vigueur.</p>

        <h3>6. Droits des personnes concernées</h3>
        <p>Conformément au RGPD, vous disposez des droits suivants sur vos données personnelles :</p>
        <ul>
          <li>Droit d'accès</li>
          <li>Droit de rectification</li>
          <li>Droit à l'effacement</li>
          <li>Droit à la limitation du traitement</li>
          <li>Droit d'opposition</li>
          <li>Droit à la portabilité des données</li>
        </ul>
        <p>Pour exercer ces droits, veuillez contacter notre Délégué à la Protection des Données à l'adresse e-mail suivante : contact@interetgeneral.net</p>

        <h3>7. Partage des données</h3>
        <p>Les données personnelles collectées ne sont jamais ni vendues ni partagées à des tiers.</p>

        <h3>8. Sécurité des données</h3>
        <p>L'association Intérêt Général met en œuvre des mesures techniques et organisationnelles appropriées pour protéger les données personnelles contre la destruction accidentelle ou illicite, la perte accidentelle, l'altération, la divulgation ou l'accès non autorisé.</p>

        <h3>9. Modifications du règlement</h3>
        <p>Ces mentions légales ont pour la dernière fois été mises à jour en août 2024.</p>
        <p>D’éventuelles mises à jour du site internet de l’association ainsi que de l’évolution de la réglementation applicable aux données personnelles peuvent conduire Intérêt général à modifier ces mentions légales. La version la plus actuelle est mise à disposition pour consultation et impression sur notre site internet. </p>

        <h3>10. Contact</h3>
        <p>Pour toute question relative à la protection des données personnelles, veuillez contacter notre Délégué à la Protection des Données à l'adresse e-mail suivante : contact@interetgeneral.net</p>

      </Content>
    </FromBottom>
  );
}

Legal.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Legal;

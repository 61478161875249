import PropTypes from "prop-types";
import {
  Container,
  Grid,
  Box,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { useGetNoteListQuery } from "../store";
import { getHelmet } from "../utilities";
import { FromBottom } from "../theme";
import Spinner from "../components/Spinner";
import NoteItem from "../components/notes/NoteItem";
import Sidebar from "../components/Sidebar";

function NotesContent({ page }) {
  const { data, isLoading, isError } = useGetNoteListQuery({
    page: page || 1,
  });

  if (isLoading) {
    return (
      <Grid item xs>
        <Box sx={{ mt: 16 }}>
          <Spinner />
        </Box>
      </Grid>
    );
  }

  if (isError || !data.items.length) {
    return (
      <Grid item xs>
        <p>Une erreur est survenue. Essayez de recharger la page.</p>
      </Grid>
    );
  }

  return (
    <Grid item xs>
      {data.items.map((note, i) => (
        <NoteItem note={note} key={i} />
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "88px 0 56px",
        }}
      >
        <Pagination
          color="secondary"
          shape="rounded"
          count={Math.ceil(data.pagination.total / data.pagination.per_page)}
          size="large"
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`/notes${item.page === 1 ? "" : `/page/${item.page}`}`}
              {...item}
            />
          )}
        />
      </Box>
    </Grid>
  );
}

NotesContent.propTypes = {
  page: PropTypes.string,
};

function Notes({ match }) {
  return (
    <FromBottom>
      <Helmet>
        {getHelmet(
          "Les publications",
          `Publications sur le site d'Intérêt Général.`,
          match.url
        )}
      </Helmet>
      <Container sx={{ my: { xs: "40px", md: "56px", lg: "64px" } }}>
        <Grid container spacing={{ xs: 4, md: 6, lg: 8 }}>
          <NotesContent page={match.params.page} />
          <Grid
            item
            lg="auto"
            xs={4}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Sidebar />
          </Grid>
        </Grid>
      </Container>
    </FromBottom>
  );
}

Notes.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      page: PropTypes.string,
    }).isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Notes;

import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Container, Grid, IconButton, SwipeableDrawer } from "@mui/material";
import styled from "@emotion/styled";

import { colors, breakpoints } from "../theme";
import igImg from "../assets/ig.svg";
import logoImg from "../assets/logo.svg";

const Title = styled(Grid)`
  height: 56px;
  margin: 0;
  padding: 12px 0;

  img {
    height: 30px;
    margin: 0 8px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    height: 75px;
    padding: 16px 0;

    img {
      height: 45px;
    }
  }
`;

const Nav = styled(Grid)`
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;

  @media (min-width: ${breakpoints.md}px) {
    padding: 0;
  }

  a {
    border-right: 2px solid ${colors.secondary.light};
    color: ${colors.primary.main};
    display: inline-block;
    font-family: "Alegreya Sans", Arial, Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s;

    :after {
      background: ${colors.primary.main};
      bottom: -10px;
      content: "";
      display: block;
      height: 4px;
      left: 15px;
      position: absolute;
      transition: 0.2s;
      width: 0;
    }

    &.active,
    :hover {
      :after {
        width: calc(50% - 15px);
      }
    }

    :last-child {
      border: 0;
      padding-right: 0;

      :hover {
        color: ${colors.secondary.main};
      }

      :after {
        content: none;
      }
    }
  }
`;

const MenuButton = styled(IconButton)`
  left: 0;
  padding: 14px 20px;
  position: absolute;
  top: 0;
`;

const MenuDrawer = styled(SwipeableDrawer)`
  a {
    border-bottom: 1px solid ${colors.divider};
    color: ${colors.primary.main};
    display: block;
    font-family: "Alegreya Sans", Arial, Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    padding: 8px 16px;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s;

    &:hover {
      background: ${colors.secondary.main};
      color: white;
    }
  }
`;

function Menu() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <Container as="nav">
      <MenuButton
        color="secondary"
        size="large"
        sx={{ display: { xs: "block", sm: "none" } }}
        onClick={() => setIsDrawerOpen(true)}
      >
        <i className="eva eva-menu-outline" />
      </MenuButton>
      <MenuDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onOpen={() => setIsDrawerOpen(true)}
      >
        <NavLink to="/" exact>
          Accueil
        </NavLink>
        <NavLink to="/notes">Les publications</NavLink>
        <NavLink to="/conseil">Le conseil</NavLink>
        <NavLink to="/qui-sommes-nous">Qui sommes-nous ?</NavLink>
        <NavLink to="/presse">Presse</NavLink>
        <NavLink to="/">Contact</NavLink>
      </MenuDrawer>

      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        sx={{
          alignItems: "stretch",
          justifyContent: { xs: "center", lg: "space-between" },
        }}
      >
        <Title item as="h1" sx={{ textAlign: { xs: "center", md: "left" } }}>
          <NavLink to="/">
            <img src={igImg} alt="IG" />
            <img
              src={logoImg}
              alt="Intérêt Général, la fabrique de l'alternative"
            />
          </NavLink>
        </Title>
        <Nav item md="auto" sx={{ display: { xs: "none", sm: "flex" } }}>
          <NavLink to="/" exact>
            Accueil
          </NavLink>
          <NavLink to="/notes">Les publications</NavLink>
          <NavLink to="/conseil">Le conseil</NavLink>
          <NavLink to="/qui-sommes-nous">Qui sommes-nous ?</NavLink>
          <NavLink to="/presse">Presse</NavLink>
          <NavLink to="/">
            <i className="eva eva-email-outline" />
          </NavLink>
        </Nav>
      </Grid>
    </Container>
  );
}

export default Menu;
